.footer-section {
  background-image: url(../../assets/banner-footer2.jpg);
  background-size: cover;
  background-position-y: 70%;
  color: white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-box {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.5rem;
}

.footer-title {
  text-align: center;
  padding-bottom: 1rem;
  font-size: 3rem;
}
.footer-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 25rem;
}

.footer-form input,
.footer-form textarea,
.footer-form button {
  font-family: "Times";
  font-weight: 700;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  border: none;
  font-size: larger;
  font-weight: 500;
  padding: 15px;
  width: 100%;
  resize: none;
}

.footer-form button {
  background: #96a7a1;
  padding: 1.2rem 4rem;
  color: white;
  font-size: large;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
}
.footer-form button:hover {
  background-color: #646f6b;
}

.footer-nav-media {
  display: flex;
  gap: 1rem;
  font-size: 2rem;
}
.footer-nav-media-ig {
  transition: color 0.1s ease-in-out;
}

.footer-nav-media-telegram {
  transition: color 0.1s ease-in-out;
}

.footer-nav-media-ig:hover {
  color: #e128bf;
}

.footer-nav-media-telegram:hover {
  color: #0088cc;
}
.contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  align-self: center;
  padding: 5px;
  font-size: inherit;
  cursor: pointer;
}

.footer-nav-icon {
  width: auto;
  height: 50px;
}

.whatsapp-logo {
  width: 25px;
}

@media (max-width: 450px) {
  .footer-section {
    background-position-x: 51.5%;
  }
  .footer-title {
    font-size: 2.5rem;
  }

  .footer-form {
    width: 100%;
  }

  div.footer-content.container {
    padding: 1rem;
  }

  .footer-nav-media {
    font-size: 1.5rem;
  }
}
