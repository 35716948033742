.banner-associate {
  width: 100%;
}

.back-to-main-associates {
  border: none;
  background: white;
  border-radius: 100%;
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 1.3rem;
}

.associates-about-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  color: #421400;
  margin: 2rem auto;
  width: 90%;
}

.associate-img {
  height: auto;
  width: 20%;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
}

.associates-info {
  width: 100%;
}

.associates-info p {
  font-size: large;
  text-align: justify;
}

.associates-info h1 {
  color: #f4b74e;
}

.associates-section-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fffcf0;
  gap: 3rem;
  padding-bottom: 1rem;
}

@media (max-width: 1024px) {
  .associates-about-section {
    flex-direction: column;
    align-items: center;
    width: 80%;
    gap: 1rem;
  }

  .associate-img {
    height: auto;
    width: 30%;
  }

  .associates-info h1 {
    text-align: center;
  }

  .back-to-main-associates {
    padding: 0.8rem;
  }
}

@media (max-width: 450px) {
  .associates-about-section {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .associates-info p {
    font-size: 16px;
  }

  .associate-img {
    height: auto;
    width: 40%;
  }

  .back-to-main-associates {
    display: none;
  }
}
