.about-container {
  display: flex;
  justify-content: space-around;
  line-height: 2rem;
  align-items: center;
  margin-bottom: 6rem;
}

.about-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 6rem;
  color: #421400;
  width: 90%;
}

.about-content article {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-content article h2 {
  font-size: 35px;
  margin: 0 0 2rem 0;
  text-align: center;
}

.about-content article p {
  font-size: larger;
  margin: 0;
  text-align: center;
  width: 80%;
  line-height: 2.8rem;
}

.mandala-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .about-mandala {
    width: 100%;
  }

  .about-content article p {
    font-size: 17px;
    line-height: 2.5rem;
  }
}

@media (max-width: 1012px) {
  .about-content article h2 {
    font-size: 30px;
  }

  .about-content article p {
    width: 100%;
    font-size: 16px;
    line-height: 2.5rem;
  }
}

@media (max-width: 820px) {
  .mandala-desktop {
    display: none;
  }

  .mandala-mobile {
    display: inline;
  }

  .about-content article p {
    text-align: justify;
    width: 75%;
    font-size: 17px;
    line-height: 2rem;
  }
}

@media (max-width: 768px) {
  .about-mandala {
    width: 100%;
  }

  .about-container {
    align-items: center;
  }

  .about-content article p {
    text-align: justify;
    width: 75%;
    font-size: 15px;
    line-height: 2rem;
  }
}

@media (max-width: 450px) {
  .about-mandala {
    display: none;
  }

  .about-content article p {
    text-align: justify;
    width: 75%;
    font-size: 14px;
    line-height: 2rem;
  }
}
