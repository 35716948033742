.phrase-container {
  color: white;
  background-color: #421400a9;
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
  padding: 4rem 0%;
  text-align: center;
}

.phrase-container h3 {
  font-size: 1.5rem;
}

@media (max-width: 1200px) {
  .phrase-container h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 855px) {
  .phrase-container h3 {
    font-size: 1.15rem;
  }
}

@media (max-width: 768px) {
  .phrase-container h3 {
    font-size: 1.2rem;
    text-align: center;
  }

  .phrase-container {
    padding: 2rem 5%;
  }
}

@media (max-width: 450px) {
  .phrase-container h3 {
    text-align: center;
  }
}
