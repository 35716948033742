.scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  background-color: #be9684;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 9;
  transition: background-color 0.3s ease-in-out;
}

.scroll-to-top-button.visible {
  display: block;
  opacity: 1;
}

.scroll-to-top-button:hover {
  background-color: #555;
  color: #fff;
}

@media (max-width: 450px) {
  .scroll-to-top-button {
    margin: 0;
    bottom: 60px;
  }
}
