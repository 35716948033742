.admin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.admin-btn {
  background: white;
  border: 2px solid #421400;
  border-radius: 4px;
  padding: 1rem;
  width: 30rem;
  font-size: 1.7rem;
}
