.background-header-banner {
  height: 80vh;
  background-image: url(../../assets/banner.jpg);
  background-size: cover;
  background-position: 70% 100%;
  margin-bottom: 5rem;
}

.banner-filter {
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 80vh;
  position: absolute;
}