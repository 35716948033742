.login-title {
  text-align: center;
  padding-bottom: 1rem;
  font-family: "Times2";
  color: #421400;
  letter-spacing: 0.1em;
}

.login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
}

.login-form input {
  height: 50px;
  width: 300px;
  background: none;
  border: none;
  border-bottom: 1px solid #421400;
}

.login-form input:focus {
  border: none;
}

.login-form button {
  font-family: "Times2";
  font-size: 1.3rem;
  color: white;
  margin-top: 1rem;
  width: 160px;
  height: 47px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #421400;
}
