.form-container {
  height: 100%;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
}

.form-container form{
  width: 68%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.form-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
}

.form-container label {
  font-weight: 600;
  text-align: left;
  font-family: "Times2";
  font-size: 18px;
  color: rgb(71, 71, 71);
}

.form-container input{
  font-family: "Times2";
}

.input-container {
  margin: 1.5rem;
}

#root > div > div > div > form > div:nth-child(3) > div.col-sm-10.d-flex > input{
  width: 65%;
  margin-right: 15px;
}

#root > div > div > div > form > div:nth-child(3) > div.col-sm-10.d-flex > div.col-sm-2{
  width: 25%;
  height: 100%;
  font-family: "Times2";
}

.form-container-div{
  display: flex;
  justify-content: center;
}

#submit{
  width: 25%;
  margin-top: 3rem;
  margin: auto;
  height: 45px;
}