.schedule {
  display: grid;
  grid-template-columns: auto repeat(5, 1fr);
  justify-content: center;
  grid-column-gap: 0.3rem;
  grid-row-gap: 0.3rem;
}

.time,
.day,
.course {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1px solid #421400;
  padding: 0.7rem;
  text-align: center;
  font-size: 1rem;
  border-radius: 4px;

}

.day {
  background-color: #be9684;
  font-weight: 700;
}

.time { 
  background-color: #e2bf8e;
  font-weight: 700;
}

.course {
  background-color: #e2bf8e3f;
}

@media (max-width: 600px) {
  .time,
  .day,
  .course {
    border: 0.1px solid #421400;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .time,
  .day,
  .course {
    border: 0.1px solid #421400;
    text-align: center;
    padding: 0.3rem;
    font-size: 0.7rem;
  }
}
