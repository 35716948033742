.banner-content {
  width: 100%;
  height: 68vh;
  position: relative;
}

.banner {
  color: #e2e2e2;
  line-height: 8vw;
  position: absolute;
  top: 25%;
  width: 33%;
  left: 14%;
  user-select: none;
}

.banner h1 {
  text-align: left;
  height: 100%;
}

.banner span {
  font-size: 1.4rem;
  width: 0%;
  height: 100%;
}

.banner-title-author {
  width: 100%;
  font-size: 1.5rem;
  text-align: end;
  margin: 0;
  justify-self: flex-end;
  height: 100%;
}

@media (max-width: 1024px) {
  .banner {
    top: 20%;
    width: 50%;
    left: 8%;
  }
}

@media (max-width: 768px) {
  .banner {
    line-height: 2vw;
    width: 60%;
    left: 8%;
    top: 20%;
  }

  .banner h1 {
    font-size: 2rem;
  }

  .banner span {
    font-size: 1.3rem;
  }

  .banner p {
    font-size: 1rem;
  }

  .banner-title-author {
    text-align: start;
  }
}

@media (max-width: 550px) {
  .banner {
    width: 100%;
    margin: 0;
    padding: 0.5rem 0.5rem;
    top: 9rem;
    left: 0;
  }

  .banner h1 {
    font-size: 1.9rem;
    text-align: center;
    line-height: 1.9rem;
  }

  .banner h1 span {
    font-size: 1.2rem;
    text-align: start;
  }

  .banner-title-author {
    text-align: end;
  }
}

@media (max-width: 425px) {
  .banner {
    padding: 0.5rem 2rem;
  }
  .banner h1 {
    font-size: 1.4rem;
  }

  .banner h1 span {
    font-size: 1.05rem;
    line-height: 1.5rem;
  }
}
