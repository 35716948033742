.card {
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
  width: 22vw;
  height: 22vw;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.reel-button {
  margin: 10px;
  background-color: rgb(122, 75, 55);
  color: white;
  border: transparent;
  padding: 7px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.wrapper {
  display: flex;
}

.slider-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.reel-container {
  margin-bottom: 6rem;
}

.reel-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 3rem;
  max-width: 100vw !important;
}

.reel-title {
  text-align: center;
  color: #421400;
  font-size: 400;
  font-size: 30px;
  margin: 0 0 2rem 0;
}

.card > a {
  background-color: rgb(122, 75, 55);
  color: white;
  font-size: 1.2rem;
  padding: 0 2rem;
  width: fit-content;
  height: fit-content;
  position: absolute;
  bottom: 1rem;
  border: none;
  border-radius: 4px;
}
.reel-btn {
  background-color: rgb(122, 75, 55);
  color: white;
  font-size: 1.2rem;
  border: none;
  border-radius: 4px;
}

.no-show-btn {
  display: none;
}
.buttons{
  margin-top: 10px;
}

@media (max-width: 550px) {
  .no-show-mb {
    display: none;
  }
  .card {
    width: 80vw;
    height: 80vw;
  }

  .slider-img {
    width: 80vw;
    height: 80vw;
  }
}
