.blogs-container {
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blogs-container > h2 {
  text-align: center;
  color: #421400;
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 2rem 0;
}
.blogs-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
}
.blog-cards-container {
  width: 48.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.blog-card {
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}
.blog-card0 {
  display: flex;
  width: 25rem;
  height: 100%;
  flex-direction: column;
}
.blog-card0 > a > img {
  width: auto;
  height: 55%;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
}
.blog-card0 > a {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.blog-card0 > a > div {
  height: 52%;
  padding: 15px;
  background-color: #fffcf0;
  border-radius: 0 0 4px 4px;
  border: 0.2px solid rgba(80, 80, 80, 0.5);
  border-top: none;
  display: flex;
  flex-direction: column;
}
.blog-card0 > a > div > div {
  height: 92%;
}
.moreButton {
  color: #421400;
  width: auto;
  margin: 0;
  font-size: 1.25rem;
  text-decoration: underline;
  text-align: right;
  align-self: flex-end;
}
.moreButton:hover {
  cursor: pointer;
}
.blog-card {
  height: 48%;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.blog-card2 {
  align-items: end;
}

.blog-card > a > h2,
.blog-card > a > h2 > a {
  font-weight: 600;
  font-size: 26px;
  text-align: right;
  color: black;
  padding: 10px;
  width: 100%;
  z-index: 9;
}
.blog-card > h2 > a {
  width: 100%;
}
.blog-card > h2 > a:hover {
  cursor: pointer;
  width: 100%;
}
.blog-card0 > a > div > div > h2 {
  color: #421400;
  font-weight: 600;
  font-size: 28px;
}
.blog-card0 > a > div > div > p {
  color: #421400;
  font-size: 17px;
}

.blog-filter {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 1;
}

.card-link {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
}
.blog-card2 > .card-link {
  align-items: end;
}

.loader {
  position: relative;
  background-color: #e3e3e3;
  overflow: hidden;
}

.loader:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    110deg,
    rgba(227, 227, 227, 0) 0%,
    rgba(227, 227, 227, 0) 40%,
    rgba(227, 227, 227, 0.5) 50%,
    rgba(227, 227, 227, 0) 60%,
    rgba(227, 227, 227, 0) 100%
  );
  animation: gradient-animation_2 1.2s linear infinite;
}
@keyframes gradient-animation_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
