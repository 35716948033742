@import url("https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #fffcf0;
  height: 100vh;
}

.App {
  font-family: "Times", serif;
}

/* @font-face {
  font-family: "Times2";
  src: url("./assets/fonts/Times.ttc") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

html {
  scroll-behavior: smooth;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.section {
  padding: 4rem 0 6rem;
}

.container {
  margin: 0 auto;
  max-width: 130vh;
  padding: 1rem 2rem;
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}
