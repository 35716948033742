/* .articles_container > * {
  font-family: "Times2";
  font-size: larger;
  margin: 0;
  text-align: center;
  line-height: 2.8rem;
} */
.articles_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-bottom: 100px;
 
}

.articles_background {
  width: 100%;
  height: 500px;
  background-image: linear-gradient(
      180deg,
      rgba(54, 73, 95, 0.75) 0%,
      rgba(54, 73, 95, 0) 100%
    ),
    url(../../assets/articles-banner.png);
  background-size: cover;
  background-position: 70% 100%;
  margin-bottom: 2rem;
}

.articles_title {
  color: #e36f20;
  font-weight: 700;
  font-size: 44px;
  margin-bottom: 2rem;
}

.articles_container > .articles_background {
  height: 350px !important;
}

.articles_container
  > .articles_background
  > .alt-header-container
  > .header_title_container
  > .titulo_header {
  text-align: center;
  color: #e36f20;
  font-weight: 500;
  font-size: 55px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
