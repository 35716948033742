header {
  position: relative;
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 15%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  padding: 1rem 2rem;
}

.header-nav {
  width: 100%;
}

.header-nav ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
  padding-inline-start: 0;
}

.nav__link {
  font-weight: 300;
  font-size: 1.1rem;
}

.nav__link:after {
  transition: all ease-in-out 0.2s;
  background: none repeat scroll 0 0 #ffffff;
  content: "";
  display: block;
  height: 2px;
  width: 0;
}

.nav__link:hover:after {
  width: 100%;
}

a {
  text-decoration: none;
  color: white;
}

.login-button {
  font-family: "Times2";
  background: none;
  padding: 3px 16px;
  border-radius: 4px;
  border: 2px solid #c8abfb;
  color: white;
  transition: background-color 0.2s ease-in-out;
  font-size: 1.2rem;
  cursor: pointer;
}

.login-button:hover {
  background-color: #c8abfb;
}

.close-menu {
  display: none;
}

.noshow {
  display: none;
}
.hamburger {
  display: none;
}

@media (max-width: 1024px) {
  .header-logo {
    width: auto;
    height: 90%;
  }

  .header-nav ul {
    gap: 2rem;
  }

  .nav__link {
    font-weight: 300;
  }

  header.header-content {
    padding: 0.5rem 0;
  }
}

@media (max-width: 768px) {

  header.header-content {
    padding: 1rem;
  }
  .header-content {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-nav {
    display: none;
    padding: 0 2rem;
  }

  .header-logo {
    width: auto;
    height: 100%;
    margin: 0 auto;
  }

  .nav-mobile {
    display: flex;
    background-color: #fffcf0;
    position: fixed;
    z-index: 999;
    right: 0;
    top: 0;
    margin: 0;
    width: 45vw;
    padding: 0;
    height: 100vh;
    align-items: flex-end;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    height: 103vh;
  }

  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {
      right: -300px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      right: -300px;
      opacity: 1;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  .nav ul {
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin-top: 3rem;
    justify-content: flex-start;
  }
  .nav-mobile ul li a,
  .nav-mobile ul li a button {
    color: #421401;
  }

  .hamburger {
    position: absolute;
    right: 50px;
    display: block;
    height: 30px;
    color: white;
  }
  .close-menu {
    display: inline-block;
    height: 30px;
    padding: 0.6rem 1rem 0 0;
    position: absolute;
    top: 0;
    right: 0;
    color: #421401;
  }
}
